<template>
    <div class="crud-actions d-flex gap-4 justify-end flex-wrap">
        <div
            v-if="requireDeletionConfirmation"
            class="d-flex justify-end gap-2">
            <v-tooltip :text="$t('buttons.cancel')">
                <template #activator="scope">
                    <v-btn
                        icon="$cancel"
                        size="small"
                        density="compact"
                        variant="text"
                        color="error"
                        v-bind="scope.props"
                        @click.prevent.stop="
                            requireDeletionConfirmation = false
                        " />
                </template>
            </v-tooltip>
            <v-tooltip :text="$t('titles.confirm_deletion')">
                <template #activator="scope">
                    <v-btn
                        icon="$check"
                        size="small"
                        density="compact"
                        variant="text"
                        color="success"
                        v-bind="scope.props"
                        @click.prevent.stop="
                            () => {
                                $emit('delete');
                                requireDeletionConfirmation = false;
                            }
                        " />
                </template>
            </v-tooltip>
        </div>
        <div
            v-else
            class="d-flex justify-end gap-2">
            <v-tooltip
                v-if="enableShow"
                :text="$t('showDetails')">
                <template #activator="scope">
                    <v-btn
                        icon="mdi-eye-outline"
                        size="small"
                        density="compact"
                        variant="text"
                        v-bind="scope.props"
                        @click.prevent.stop="$emit('show')" />
                </template>
            </v-tooltip>
            <v-tooltip
                v-if="enableEdit"
                :text="$t('buttons.edit')">
                <template #activator="scope">
                    <v-btn
                        icon="$edit"
                        size="small"
                        density="compact"
                        variant="text"
                        v-bind="scope.props"
                        @click.prevent.stop="$emit('edit')" />
                </template>
            </v-tooltip>
            <v-tooltip
                v-if="enableDelete"
                :text="$t('buttons.delete')">
                <template #activator="scope">
                    <v-btn
                        icon="$delete"
                        size="small"
                        density="compact"
                        variant="text"
                        color="error"
                        v-bind="scope.props"
                        @click.prevent.stop="
                            requireDeletionConfirmation = true
                        " />
                </template>
            </v-tooltip>
        </div>
    </div>
</template>
<script setup lang="ts">
type TProps = {
    enableEdit?: boolean;
    enableDelete?: boolean;
    enableShow?: boolean;
};

withDefaults(defineProps<TProps>(), {
    enableDelete: false,
    enableEdit: false,
    enableShow: false,
});

type TEmits = {
    (e: 'edit'): void;
    (e: 'delete'): void;
    (e: 'show'): void;
};
defineEmits<TEmits>();

const requireDeletionConfirmation = ref(false);
</script>
